@import url('https://fonts.cdnfonts.com/css/monument-extended');

* {
  padding: 0;
  margin: 0;
  font-family: 'Monument Extended', sans-serif;
  scroll-behavior: smooth;
}

body {
  background: linear-gradient(180deg, rgba(119, 50, 13, 1) 0%, rgba(252, 100, 19, 1) 35%, rgba(228, 129, 76, 1) 100%);
}

.bg {
  background-image: url('./img/afrowave_background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/*NAVBAR*/

header {
  position: absolute;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FC6413;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-size: 50%;
  z-index: 2;
}

nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

header .nav-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

header .nav-btn {
  visibility: hidden;
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  opacity: 0;
}

.open {
  z-index: 0;
}

.Xmark {
  transition: 1s;
}

nav .link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  font-size: 1.1em;
  padding: 0 35px;
  color: #fff;
  transition: 0.2s;
  cursor: pointer;
  border-left: #85350b 2px solid;
  text-decoration: none;
}

nav .link:hover {
  background-color: #85350b;
}

nav .tickets {
  border-left: #85350b 2px solid;
  border-right: #85350b 2px solid;
}

nav .tickets button {
  display: flex;
  align-items: center;
  height: 45px;
  font-size: 1.1em;
  padding: 0 35px;
  color: #fff;
  transition: 0.2s;
  cursor: pointer;
}

nav .past_festivals {
  border-left: #85350b 2px solid;
}

nav .past_festivals button {
  display: flex;
  align-items: center;
  height: 45px;
  font-size: 1.1em;
  padding: 0 35px;
  color: #fff;
  transition: 0.2s;
  cursor: pointer;
}

nav .past_festivals .dropdown-content {
  width: 13.9%;
}

.dropdown {
  float: left;
  overflow: hidden;
}

nav .dropdown .dropbtn {
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

nav .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 9.1%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

nav .dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

nav .dropdown-content a:hover {
  background-color: #ddd;
}

nav .dropdown:hover .dropdown-content {
  display: block;
}

@media only screen and (max-width: 1024px) {
  header {
    background-size: 100%;
    background-color: rgb(0, 0, 0, 0.5);
  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    transition: 1s;
    background-color: #000;
    transform: translateY(-100vh);
    z-index: 2;
    background-size: 150%;
    font-size: 2em;
  }

  nav .link {
    font-size: 1em;
    padding: 5px;
    height: 40px;
    border: none;
  }

  nav .link:hover {
    background-color: #FC6413;
  }

  nav .tickets {
    border: none;
  }

  nav .tickets .dropdown-content {
    font-size: 1em;
  }

  nav .tickets {
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  nav .past_festivals {
    border: none;
  }

  nav .past_festivals .dropdown-content {
    font-size: 1em;
    width: 30%;
  }

  nav .past_festivals {
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  header .responsive_nav {
    transform: none;
  }
}

/*HOME*/

.dropdown-ticket {
  width: 300px;
  background: #fff;
  margin: 40px auto;
  z-index: 0;
}

.dropdown-ticket span {
  padding: 30px;
  background: #FC6413;
  color: white;
  font-size: 1.3em;
  font-variant: small-caps;
  cursor: pointer;
  display: block;
  box-shadow: #000 2px 2px 10px;
}

.dropdown-ticket span::after {
  float: right;
  right: 10%;
  content: "+";
}

.slide {
  clear: both;
  width: 100%;
  height: 0px;
  overflow: hidden;
  text-align: center;
  transition: height .4s ease;
  color: #000;
}

.slide a li {
  padding: 20px;
  transition: 0.3s;
}

.slide a li:hover {
  color: #fff;
  background-color: #4d4d4d;
}

.slide a li {
  font-size: 2em;
}

#touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

#touch:checked+.slide {
  height: 260px;
}

.tickets-onsale-home {
  font-size: 2.5em;
  text-shadow: #000 2px 2px 10px;
  text-align: center;
}

.date-info-home {
  font-size: 2em;
  text-align: center;
}

.location-info-home {
  text-align: center;
  font-size: 2em;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.center {
  color: #fff;
}

.afrowave-color {
  color: #FC6413;
}

.green-color {
  color: #32B24B;
}

.afrowave-color-bg {
  color: #000;
  background-color: #fff;
  transition: 0.2s;
}

.afrowave-color-bg:hover {
  background-color: #32B24B;
  color: #fff;
}

.ticket-button {
  background-color: #FC6413;
  box-shadow: #000 3px 3px 10px;
  color: #fff;
  transition: 0.5s;
  padding: 10px 20px;
  font-size: 2em;
}

.ticket-button:hover {
  background-color: #fff;
  color: #000;
}

.logo {
  width: 22%;
  padding: 20px;
}

@media only screen and (max-width: 824px) {
  .tickets-onsale-home {
    font-size: 2em;
    text-shadow: #000 2px 2px 10px;
    text-align: center;
  }

  .date-info-home {
    font-size: 1.5em;
    text-align: center;
  }

  .location-info-home {
    text-align: center;
    font-size: 1em;
  }

  .logo {
    width: 70%;
  }
}

/*ALL CARDS*/
.artist-card {
  height: 100%;
  width: 350px;
  margin: 20px;
  background-color: #fff;
  border-radius: 5%;
  transition: 0.5s;
  color: #fff;
}

.artist-card:hover {
  transform: scale(1.03);
}

.artist-card>.artist-image {
  border-radius: 5% 5% 0 0;
  opacity: 1;
}

.artist-desc {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.artist-desc p {
  font-size: 1.5em;
  text-align: center;
}

.artist-desc .artist-socials a {
  padding: 5px;
}

/*CARD ONE*/
.artist-card-1 {
  background-color: #5c88ff;
}

.artist-socials a {
  padding: 5px;
  font-size: 1.5em;
  transition: 0.3s;
}

.hide {
  opacity: 0;
  transition: 0.5s;
  font-size: 1.2em;
}

.hide a:hover {
  color: #FC6413;
}

.artist-card:hover .hide {
  opacity: 1;
  transform: translateY(-10px);
}

.artist-image {
  transition: 0.5s;
}

.artist-card:hover .artist-image {
  filter: brightness(50%);
}

.artist-desc p {
  transition: 0.5s ease;
  transform: translateY(30px);
}

.artist-card:hover .artist-desc p {
  transform: translateY(-2px) scale(1.1);
}

.circle {
  border-radius: 4rem;
  padding: 0 10px;
  border: 1px solid #fff;
}

.artist-desc h6 {
  opacity: 0;
  font-size: 1em;
  transition: 0.5s;
  text-transform: uppercase;
}

.artist-card:hover .artist-desc h6 {
  opacity: 1;
  transform: translateY(-5px);
}

/*CARD 2*/
.artist-card-2 {
  background-color: #bd0016;
}

/*CARD 3*/
.artist-card-3 {
  background-color: #478d95;
}

/*CARD 4*/
.artist-card-4 {
  background-color: #f89b55;
}

/*CARD 5*/
.artist-card-5 {
  background-color: #ee7155;
}

/*CARD 6*/
.artist-card-6 {
  background-color: #87888d;
}

/*CARD 7*/
.artist-card-7 {
  background-color: #d83d1c;
}

/*CARD 8*/
.artist-card-8 {
  background-color: #686868;
}

/*CARD 9*/
.artist-card-9 {
  background-color: #015069;
}

/*CARD 10*/
.artist-card-10 {
  background-color: #49251b;
}

/*CARD RESPONSIVE*/
@media only screen and (max-width: 824px) {
  .artist-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .artist-desc p {
    font-size: 1.5em;
  }

  .swiper-wrapper {
    display: flex;
    flex-direction: row;
  }

  .artist-card {
    height: 25%;
    width: 100%;
  }
}

@media only screen and (max-width: 524px) {
  .artist-card {
    height: 25%;
    width: fit-content;
  }
}

/*SWIPER*/
.swiper-wrapper {
  display: flex;
  flex-direction: row;
}

.swiper-button-next::after {
  color: #fff;
}

.swiper-button-prev::after {
  color: #fff;
}

@media screen and (min-width: 390px) {
  .swiper-container {
    width: 390px;
  }
}

@media screen and (min-width: 480px) {
  .swiper-container {
    width: 480px;
  }
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}


/*LINEUP*/

.flyers {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 20px;
}

.event-flyer {
  z-index: 1;
  height: 100%;
}

.event-flyer-2 {
  display: none;
}

.video-section {
  padding: 20px;
}

.video-section p {
  text-align: center;
  color: #fff;
  font-size: 3em;
}

.video-section h5 {
  text-align: center;
  color: #fff;
  font-size: 2em;
  padding-bottom: 15px;
}

.video {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Video2023 {
  z-index: 1;
  width: 75%;
  height: 750px;
}

.gallery p {
  text-align: center;
  color: #fff;
  font-size: 3em;
  padding-bottom: 15px;
}

@media only screen and (max-width: 1024px) {
  #Video2023 {
    width: 100%;
    height: 650px;
  }
}

@media only screen and (max-width: 824px) {
  #Video2023 {
    width: 100%;
    height: 500px;
  }
}

/*INFO*/

.info {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: #fff;
  width: 75%;
  margin-top: 5%;
  padding: 5% 0;
  background-color: #000;
  background-image: url("./img/afrowave_background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.dates-info {
  width: 75%;
  padding: 20px 0;
  text-shadow: #000 2px 2px 5px;
}

.dates-info .title {
  text-transform: uppercase;
}

.dates-info hr {
  padding: 5px;
}

.location-info {
  width: 75%;
  padding: 20px 0;
  text-shadow: #000 2px 2px 5px;
}

.location-info .title {
  text-transform: uppercase;
}

.location-info hr {
  padding: 5px;
}

.parking-info {
  width: 75%;
  padding: 20px 0;
  text-shadow: #000 2px 2px 5px;
}

.parking-info hr {
  padding: 5px;
}

.parking-info .title {
  text-transform: uppercase;
}

.sponsors {
  height: 100%;
  padding: 50px;
}

.sponsors .title {
  font-size: 3em;
  color: #fff;
  text-align: center;
}

.sponsors-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.sponsors-images img {
  width: 15%;
  height: 15%;
  padding: 10px;
}

.sponsors-images .FRAN {
  width: 5%;
  height: 5%;
}

@media only screen and (max-width: 824px) {

  .info {
    width: 100%;
  }

  .flyers {
    height: 100vh;
    padding: 20px;
  }

  .event-flyer {
    display: none;
  }

  .event-flyer-2 {
    display: inherit;
    height: 100%;
    width: 100%;
  }

  .sponsors-images {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .sponsors-images img {
    width: 50%;
    height: 50%;
    padding: 10px;
  }

  .sponsors-images .FRAN {
    width: 25%;
    height: 25%;
  }
}


/*LINEUP*/
.spacer {
  height: 5vh;
}

.artists {
  padding-top: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.artists hr {
  width: 80%;
}

.artists .title {
  font-size: 3em;
  color: #fff;
}

.artists .images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 25px;
}

.artists .images-ios {
  display: none;
  padding: 25px;
}

.lineup-date-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #FC6413;
}

.lineup-date-links a {
  text-align: center;
  width: 33.333333333333%;
  padding: 10px;
  font-size: 1.5em;
  color: #fff;
  transition: 0.5s;
  background-color: #FC6413;
}


.lineup-date-links a:hover {
  background-color: #85350b;
}

.artist-lineup-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  flex-wrap: wrap;
}

.artist-lineup-name {
  position: absolute;
  opacity: 0;
  z-index: 1;
  color: #fff;
  transition: 0.5s;
  font-size: 1.5em;
  text-shadow: #000 2px 2px 7px;
}

.artist-lineup-image {
  transition: 0.5s;
}

.artist-lineup-image:hover {
  filter: brightness(40%);
}

.artist-lineup-card:hover .artist-lineup-name {
  opacity: 1;
  transform: scale(1.1);
}


@media only screen and (max-width: 1424px) {
  .artist-lineup-card {
    width: 35%;
  }
}

@media only screen and (max-width: 1024px) {

  .artists .images-ios {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .artists .images {
    display: none;
  }

  .artists div img {
    width: 70%;
  }

  .artist-lineup-card {
    width: 100%;
  }

  .lineup-date-links {
    font-size: 1.5em;
    flex-direction: column;
  }

  .lineup-date-links a {
    width: 100%;
  }

  .spacer {
    display: none;
  }
}

@media only screen and (max-width: 624px) {
  .artists div img {
    width: 100%;
    padding: 10px;
  }
}

/*PAST FESTIVALS*/

#Video2022 {
  z-index: 1;
  width: 75%;
  height: 600px;
}

.lineup2022 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lineup2022 img {
  padding: 20px;
  width: 50%;
}

.artists_2022 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.artists_2022 img {
  width: 30%;
  padding: 10px;
}

.lineup2021 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.lineup2021 img {
  padding: 20px;
  width: 50%;
}

.past-artists {
  padding-top: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.past-artists .images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 25px;
}

.past-artist-lineup-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  flex-wrap: wrap;
  margin: 10px;
}

.past-artist-lineup-name {
  position: absolute;
  opacity: 0;
  z-index: 1;
  color: #fff;
  transition: 0.5s;
  font-size: 1.5em;
  text-shadow: #000 2px 2px 7px;
}

.past-artist-lineup-image {
  transition: 0.5s;
}

.past-artist-lineup-image:hover {
  filter: brightness(40%);
}

.past-artist-lineup-card:hover .past-artist-lineup-name {
  opacity: 1;
  transform: scale(1.1);
}


@media only screen and (max-width: 1424px) {
  .past-artist-lineup-card {
    width: 35%;
  }
}

@media only screen and (max-width: 624px) {
  .artists div img {
    width: 100%;
    padding: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .past-artists div img {
    width: 90%;
  }

  .past-artist-lineup-card {
    width: 100%;
  }

  #Video2023 {
    width: 100%;
    height: 650px;
  }

  #Video2022 {
    width: 1000px;
    height: 650px;
  }

  .lineup2022 img {
    padding: 10px;
    width: 70%;
  }

  .lineup2021 {
    flex-direction: column;
  }

  .lineup2021 img {
    width: 70%;
    padding: 10px;
  }

  .artists_2022 {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .artists_2022 img {
    width: 30%;
    padding: 5px;
  }
}

@media only screen and (max-width: 824px) {
  #Video2022 {
    width: 100%;
    height: 300px;
  }

  .lineup2022 img {
    padding: 10px;
    width: 100%;
  }

  .lineup2021 {
    flex-direction: column;
  }

  .lineup2021 img {
    width: 100%;
    padding: 10px;
  }

  .artists_2022 {
    flex-direction: row;
  }

  .artists_2022 img {
    width: 50%;
  }
}


/*FOOTER*/

.footer {
  background-color: #2b2b2b;
}

.footer .socials {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.footer .socials a {
  font-size: 1.5em;
  padding: 0px 15px;
  color: #fff;
  transition: 0.5s;
}

.footer .socials a:hover {
  color: #32B24B;
}

/*CONTACT*/

.contact {
  padding-top: 50px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  background-image: url("./img/afrowave_background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-from {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.fundedby {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
}

.fundedby .title {
  color: #fff;
  font-size: 2em;
}

.fundedby-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
  margin-bottom: 40px;
}

.fundedby-image img {
  width: 30%;
  padding: 0 20px;
}

@media only screen and (max-width: 1024px) {
  .fundedby-image {
    flex-direction: column;
    padding: 0;
  }

  .fundedby-image img {
    width: 100%;
    padding: 10px;
  }
}